import { createRouter, createWebHistory } from 'vue-router'
import { stateClasses } from '^/utils/markup'
import { useScrollStore } from '^/stores/scroll'

import HomeView from '^/views/HomeView.vue'
import ProductView from '^/views/ProductView.vue'
import ProductPreviewView from '^/views/ProductPreviewView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/products/:slug',
        name: 'product',
        props: true,
        component: ProductView
    },
    {
        path: '/products_preview',
        name: 'product-preview',
        component: ProductPreviewView
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: HomeView
    }
]

const router = createRouter({
    history: createWebHistory(),
    linkActiveClass: stateClasses.ACTIVE,
    linkExactActiveClass: stateClasses.ACTIVE_EXACT,
    defaultRedirect: 'home',
    scrollBehavior(to, from) {
        // scroll to section on the same page; scrolling to sections
        // on different pages is handled in App.vue
        const scrollStore = useScrollStore()
        if (to.path === from.path && to.hash) {
            scrollStore.scrollTo(to.hash)
        }
    },
    routes
})

router.afterEach((to, from) => {
    if (!from.name) {
        to.meta.transition = 'fade'
    } else {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        to.meta.transition = toDepth === fromDepth ? 'fade' : toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
})

export default router;
