const stateClasses = {};
stateClasses.ACTIVE = 'state-active';
stateClasses.ACTIVE_EXACT = 'state-active--exact';
stateClasses.STICKY = 'state-sticky';
stateClasses.FIXED = 'state-fixed';
stateClasses.MINIMAL = 'state-minimal';
stateClasses.OPEN = 'state-open';
stateClasses.CLOSED = 'state-closed';
stateClasses.HIDDEN = 'state-hidden';
stateClasses.NOANIMATE = 'state-noanimate';
stateClasses.LOADING = 'state-loading';
stateClasses.INITIALIZED = 'state-initialized';
stateClasses.UNINITIALIZED = 'state-uninitialized';
stateClasses.PENDING = 'state-pending';
stateClasses.SUCCESS = 'state-success';
stateClasses.ERROR = 'state-error';
stateClasses.WARNING = 'state-warning';
stateClasses.SELECTED = 'state-selected';
stateClasses.DISABLED = 'state-disabled';
stateClasses.FOCUSED = 'state-focused';
stateClasses.EMPTY = 'state-empty';
stateClasses.HIGHLIGHTED = 'state-highlighted';

const getElementClass = (rootClass, elementName) => {
    return `${rootClass}__${elementName}`;
};

const getModifierClasses = (rootClass, variants) => {
    variants = Array.isArray(variants) ? variants : (variants ? [variants] : []);
    return variants.map(variant => `${rootClass}--${variant}`);
};

const DOC_TITLE_SEPARATOR = '|';

export {
    stateClasses,
    getElementClass,
    getModifierClasses,
    DOC_TITLE_SEPARATOR
};
