<template>
   <section class='superhero' :class='classes'>
        <div class='superhero__grid grid'>
            <div class='superhero__grid__col superhero__grid__col--title grid__col'>
                <!-- <h1 v-for='i in 2' class='superhero__title' :class='`superhero__title--${i}`'>
                    <span class='superhero__title__line superhero__title__line--1'>
                        Merch
                    </span>
                    <span class='superhero__title__line superhero__title__line--2'>
                        Avail&ndash;
                    </span>
                    <span class='superhero__title__line superhero__title__line--3'>
                        able
                    </span>
                    <span class='superhero__title__line superhero__title__line--4'>
                        Now!
                    </span>
                </h1> -->

                <h1 v-for='i in 2' class='superhero__title superhero__title--bigger' :class='`superhero__title--${i}`'>
                    <span class='superhero__title__line superhero__title__line--1'>
                        The
                    </span>
                    <span class='superhero__title__line superhero__title__line--2'>
                        Official
                    </span>
                    <span class='superhero__title__line superhero__title__line--3'>
                        Merch
                    </span>
                    <span class='superhero__title__line superhero__title__line--4'>
                        Store
                    </span>
                </h1>

                <div class='superhero__actions superhero__actions--for-bigger-title'>
                    <router-link class='superhero__actions__cta' :to='{name: "home", hash: "#products"}'>
                        <span class='superhero__actions__cta__text'>
                            <span>See </span><span>products</span>
                        </span>
                        <span class='superhero__actions__cta__caret'>
                            <SvgIconCaretDown />
                        </span>
                    </router-link>
                </div>

                <a v-if='primeVideoProductLink' aria-label="Watch now on Amazon Prime" :href='primeVideoProductLink' class='superhero__badge superhero__badge--for-bigger-title superhero__badge--is-linked' rel='noopener' target='_blank'>
                    <SvgIconPrimeBadge :isWatchable='true'/>
                </a>
                <div v-else class='superhero__badge superhero__badge--for-bigger-title' aria-label="Coming soon on Amazon Prime">
                    <SvgIconPrimeBadge :isWatchable='false' />
                </div>
            </div>

            <div class='superhero__grid__col superhero__grid__col--media grid__col'>
                <div class='superhero__media'>
                    <div class='superhero__media__foreground' :style='characterImage.style'>
                        <img class='superhero__media__foreground__img' :src='isUseIndexedCharacterImagePngs ? characterImage.srcIndexed : characterImage.src' :alt='characterImage.name' />
                    </div>
                    
                    <div class='superhero__media__background'>
                        <video ref='video' class='superhero__media__video' xmlns='http://www.w3.org/1999/xhtml' width='306' height='382' loop muted playsinline preload='auto' poster='@/media/smile-poster.jpg'>
                            <source src='@/media/smile.mp4' type='video/mp4' />
                        </video>
                    </div>
                </div>
            </div>
        </div>
   </section>
</template>

<script>
import { mapActions } from 'pinia';
import { useScrollStore } from '^/stores/scroll';
import SvgIconCaretDown from '^/components/svg/SvgIconCaretDown.vue';
import SvgIconPrimeBadge from '^/components/svg/SvgIconPrimeBadge.vue';

export default {
    name: 'SuperheroTier',
    data: () => ({
        inView: true,
        isUseIndexedCharacterImagePngs: true
    }),
    computed: {
        classes () {
            return {
                'state-inview': this.inView
            }
        },
        characterImage () {
            const images = [
                {
                    name: 'Charlie',
                    src: new URL('@/media/charlie.recrop.png', import.meta.url).href,
                    srcIndexed: new URL('@/media/charlie.recrop.indexed.png', import.meta.url).href
                },
                {
                    name: 'Vox',
                    src: new URL('@/media/vox.png', import.meta.url).href,
                    srcIndexed: new URL('@/media/vox.indexed.png', import.meta.url).href,
                    style: {
                        transform: 'translateX(12.5%)'
                    }
                },
                {
                    name: 'Angel Dust',
                    src: new URL('@/media/angeldust.png', import.meta.url).href,
                    srcIndexed: new URL('@/media/angeldust.indexed.png', import.meta.url).href
                },
                {
                    name: 'Alastor',
                    src: new URL('@/media/alastor.png', import.meta.url).href,
                    srcIndexed: new URL('@/media/alastor.indexed.png', import.meta.url).href,
                    style: {
                        transform: 'scaleX(-1)'
                    }
                }
            ]
            
            return images[Math.floor(Math.random() * images.length)]
        },
        primeVideoProductLink () {
            return window.app.primeVideoProductLink || ''
        }
    },
    mounted () {
        this.observer = new window.IntersectionObserver(this.intersect);
        this.observer.observe(this.$el);
    },
    beforeUnmount () {
        this.observer.unobserve(this.$el);
    },
    methods: {
        ...mapActions(useScrollStore, [
            'scrollTo'
        ]),
        intersect (entries) {
            if (entries[0].isIntersecting) {
                this.inView = true;
                this.$refs.video.play();
            } else {
                this.inView = false;
                this.$refs.video.pause();
            }
        }
    },
    components: {
        SvgIconCaretDown,
        SvgIconPrimeBadge
    }
};
</script>