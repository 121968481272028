<template>
    <header class='header' :class='headerClasses' role='region' aria-label='Header'>
        <!-- full nav -->
        <nav class='header__nav' ref='nav' :aria-hidden='isNavVisible ? false : true'>

            <router-link :to='{name: "home"}' class='header__nav__logo'>
                <SvgLogoHazbin />
            </router-link>

            <div class='header__nav__spacer header__nav__spacer--on-scroll'>
                <router-link :to='{name: "home"}'>
                    <SvgNavPentagram />
                </router-link>
                <SvgNavLineCenter />
            </div>

            <div class='header__nav__spacer header__nav__spacer--top'>
                <SvgNavDiamondLarge />
                <SvgNavLineCenter />
                <SvgNavLineBottom />
            </div>

            <router-link
                :to='{name: "home", hash: "#products"}'
                class='header__nav__link'
                :class='navLinkClasses("products")'
                tabinex='0'>
                Shop
            </router-link>

            <div class='header__nav__spacer header__nav__spacer--middle'>
                <SvgNavLineTop />
                <SvgNavLineCenter />
                <SvgNavDiamondSmallSingle />
                <SvgNavDiamondSmallTriple />
                <SvgNavLineCenter />
                <SvgNavLineBottom />
            </div>

            <router-link
                :to='{name: "home", hash: "#about"}'
                class='header__nav__link'
                :class='navLinkClasses("about")'
                tabinex='0'>
                How to Watch
            </router-link>

            <div class='header__nav__spacer header__nav__spacer--middle'>
                <SvgNavLineTop />
                <SvgNavLineCenter />
                <SvgNavDiamondSmallSingle />
                <SvgNavDiamondSmallTriple />
                <SvgNavLineCenter />
                <SvgNavLineBottom />
            </div>

            <router-link
                :to='{name: "home", hash: "#faqs"}'
                class='header__nav__link'
                :class='navLinkClasses("faqs")'
                tabinex='0'>
                FAQs
            </router-link>

            <div class='header__nav__spacer header__nav__spacer--bottom'>
                <SvgNavLineTop />
                <SvgNavLineCenter />
                <SvgNavDiamondLarge />
            </div>
        </nav>

        <!-- mobilenav -->
        <nav class='header__mobilenav' ref='mobilenav'>
            <div class='header__mobilenav__menu'>
                <div class='header__mobilenav__menu__left'>
                    <button class='header__mobilenav__trigger'
                        tabindex="0"
                        v-bind='overlayTriggerAttrs'
                        @click='toggleMobilenavOverlay'
                        @keydown.enter='toggleMobilenavOverlay'>

                        <div class='header__mobilenav__hamburger' :class='{"header__mobilenav__hamburger--mobilenav-open": isMobilenavOverlayOpen}' aria-label='Toggle Menu'>
                            <span class='header__mobilenav__hamburger__line header__mobilenav__hamburger__line--1'></span>
                            <span class='header__mobilenav__hamburger__line header__mobilenav__hamburger__line--2'></span>
                            <span class='header__mobilenav__hamburger__line header__mobilenav__hamburger__line--3'></span>
                        </div>
                    </button>
                </div>

                <div class='header__mobilenav__menu__center'>
                    <router-link to='/' class='header__mobilenav__logo'>
                        <SvgLogoHazbinMobile />
                    </router-link>
                </div>

                <div class='header__mobilenav__menu__right'>
                    <!-- leave empty -->
                </div>
            </div>

            <!-- mobilenav overlay -->
            <div class='header__mobilenav__overlay'>
                <collapse 
                    class='header__mobilenav__overlay__drawer v-collapse'
                    v-bind='overlayDrawerAttrs'
                    :when='isMobilenavOverlayOpen'>

                    <div class='header__mobilenav__overlay__inner' :aria-hidden='isMobileNavVisible ? false : true'>

                        <div class='header__mobilenav__overlay__links'>
                            <router-link
                                :to='{name: "home", hash: "#products"}'
                                class='header__mobilenav__overlay__link'
                                :class='navLinkClasses("products")'
                                @click='isMobilenavOverlayOpen = false'>
                                <span>Shop</span>
                            </router-link>

                            <router-link
                                :to='{name: "home", hash: "#about"}'
                                class='header__mobilenav__overlay__link'
                                :class='navLinkClasses("about")'
                                @click='isMobilenavOverlayOpen = false'>
                                <span>How to Watch</span>
                            </router-link>

                            <router-link
                                :to='{name: "home", hash: "#faqs"}'
                                class='header__mobilenav__overlay__link'
                                :class='navLinkClasses("faqs")'
                                @click='isMobilenavOverlayOpen = false'>
                                <span>FAQs</span>
                            </router-link>
                        </div>
                    
                    </div>
                </collapse>
            </div>
        </nav>
    </header>
</template>

<script>
import { Collapse } from 'vue-collapsed';

import { mapState, mapActions } from 'pinia';
import { useScrollStore } from '^/stores/scroll';

import SvgLogoHazbin from '^/components/svg/SvgLogoHazbin.vue';
import SvgLogoHazbinMobile from '^/components/svg/SvgLogoHazbinMobile.vue';
import SvgNavDiamondLarge from '^/components/svg/SvgNavDiamondLarge.vue';
import SvgNavDiamondSmallSingle from '^/components/svg/SvgNavDiamondSmallSingle.vue';
import SvgNavDiamondSmallTriple from '^/components/svg/SvgNavDiamondSmallTriple.vue';
import SvgNavLineBottom from '^/components/svg/SvgNavLineBottom.vue';
import SvgNavLineCenter from '^/components/svg/SvgNavLineCenter.vue';
import SvgNavLineTop from '^/components/svg/SvgNavLineTop.vue';
import SvgNavPentagram from '^/components/svg/SvgNavPentagram.vue';

export default {
    name: 'Header',
    data() {
        return {
            isMobilenavOverlayOpen: false,
            isNavVisible: true,
            isMobileNavVisible: false
        }
    },
    computed: {
        ...mapState(useScrollStore, [
            'isScrolled',
            'scrollDirection',
            'currentSectionId'
        ]),
        headerClasses () {
            const { isScrolled, scrollDirection, isMobilenavOverlayOpen } = this;

            return {
                'header--scrolled': isScrolled,
                'header--scrolling-down': scrollDirection === 1,
                'header--scrolling-up': scrollDirection === -1,
                'header--mobilenav-overlay-open': isMobilenavOverlayOpen,
                'header--mobilenav-transparent': this.$route.name === 'product'
            }
        },
        overlayTriggerAttrs () {
            const { isMobilenavOverlayOpen } = this;

            return {
                'id': 'header-mobilenav-trigger',
                'aria-controls': 'header-mobilenav',
                'aria-expanded': isMobilenavOverlayOpen,
                'aria-label': isMobilenavOverlayOpen ? 'Close' : 'Menu',
                'tabindex': '0'
            }
        },
        overlayDrawerAttrs () {
            return {
                'id': 'header-mobilenav',
                'role': 'region',
                'aria-labelledby': 'header-mobilenav-trigger'
            }
        }
    },
    mounted() {
        this.updateSavedVisibilityState()
        window.addEventListener('resize', this.onResize)
        window.addEventListener('orientationchange', this.onResize)
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('orientationchange', this.onResize)
    },
    methods: {
        ...mapActions(useScrollStore, [
            'scrollTo'
        ]),
        toggleMobilenavOverlay () {
            this.isMobilenavOverlayOpen = !this.isMobilenavOverlayOpen;
        },
        navLinkClasses (id) {
            return {
                'state-inview': this.currentSectionId === id
            }
        },
        updateSavedVisibilityState () {
            this.isNavVisible = this.$refs.nav.offsetWidth > 0
            this.isMobileNavVisible = this.$refs.mobilenav.offsetWidth > 0
        },
        onResize () {
            this.updateSavedVisibilityState()
        }
    },
    components: {
        Collapse,
        SvgLogoHazbin,
        SvgLogoHazbinMobile,
        SvgNavDiamondLarge,
        SvgNavDiamondSmallSingle,
        SvgNavDiamondSmallTriple,
        SvgNavLineBottom,
        SvgNavLineCenter,
        SvgNavLineTop,
        SvgNavPentagram
    }
};
</script>