<template>
    <div class='view view--home'>
        <div data-scroll-section>
            <SuperheroTier id='hero' data-scroll-id='hero' data-scroll />
        </div>
        <div data-scroll-section>
            <HomepageProductsTier id='products' data-scroll-id='products' data-scroll />
        </div>
       <!-- <div data-scroll-section>
            <PackagesTier id='section-packages' data-scroll-id='section-packages' data-scroll />
        </div> -->
        <div data-scroll-section>
            <AboutTier id='about' data-scroll-id='about' data-scroll />
        </div>
        <div data-scroll-section>
            <FaqTier id='faqs' data-scroll-id='faqs' data-scroll />
        </div>

        <div data-scroll-section>
            <Footer :isShowPrefooter='true' id='footer' data-scroll-id='footer' data-scroll />
        </div>
    </div>
</template>


<script>
import SuperheroTier from '^/components/tiers/SuperheroTier.vue'
import HomepageProductsTier from '^/components/tiers/HomepageProductsTier.vue'
// import PackagesTier from '^/components/tiers/PackagesTier.vue'
import AboutTier from '^/components/tiers/AboutTier.vue'
import FaqTier from '^/components/tiers/FaqTier.vue'
import Footer from '^/components/Footer.vue'

export default {
    name: 'HomeView',
    components: {
        SuperheroTier,
        HomepageProductsTier,
        // PackagesTier,
        AboutTier,
        FaqTier,
        Footer
    }
}
</script>