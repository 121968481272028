<template>

   <section class='homepage-products'>
        <div class='homepage-products__grid grid'>
            <div class='homepage-products__grid__col grid__col'>
                <div class='homepage-products__items'>
                    <router-link v-for='(item, i) in products'
                        class='homepage-products__item'
                        :class='{
                            "homepage-products__item--has-hover-img": item.media.length > 1
                        }'
                        :key='i'
                        :to='{ name: "product", params: { slug: item.handle }}'>
                        
                        <div class='homepage-products__item__thumb'>
                            <div v-if='item.media.length > 0' class='homepage-products__item__thumb__default'>
                                <Media :media='item.media[0]' :isFullbleed='true' :alt="item.media[0]?.alt || item.title" />
                            </div>
                            <div v-if='item.media.length > 1' class='homepage-products__item__thumb__hover'>
                                <Media :media='item.media[1]' :isFullbleed='true' :alt="item.media[1]?.alt || `Second image for ${item.title}`" />
                            </div>
                        </div>
                        <div class='homepage-products__item__info'>
                            <div class='homepage-products__item__info__title'>
                                <span v-for='(word, j) in productTitleArray(item.title)' :key='`word-${j}`' v-text='word + "&#32;"' />
                            </div>
                            <div class='homepage-products__item__info__price'>
                                <template v-if='item.price === 0'>
                                    <span class='homepage-products__item__info__price__free' v-text='"Free"' />
                                </template>
                                <template v-else>
                                    <span class='homepage-products__item__info__price__sign' v-text='"$"' />
                                    <span class='homepage-products__item__info__price__dollars' v-text='getPriceDollars(item.price)' />
                                    <span class='homepage-products__item__info__price__dot' v-text='"."' />
                                    <span class='homepage-products__item__info__price__cents' v-text='getPriceCents(item.price)' />
                                </template>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
   </section>

</template>

<script>
import { productTitleArray } from '^/utils/typography'
import Media from '^/components/elements/Media.vue'
import Spinner from '^/components/elements/Spinner.vue'

export default {
    name: 'HomepageProductsTier',
    data: () => ({
        collection: null,
        products: []
    }),
    created () {
        fetch('/collections/homepage?view=json').then(response => {
            return response.json()
        }).then(data => {
            this.collection = data.collection
            this.products = data.products
        })
    },
    methods: {
        getPriceDollars (price) {
            return Math.floor(price / 100)
        },
        getPriceCents (price) {
            return price % 100 || '00'
        },
        productTitleArray: productTitleArray
    },
    components: {
        Media,
        Spinner
    }
};
</script>